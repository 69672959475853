import React from 'react';

import { songList } from '../data/songs';

interface Props {
  songSelection: number[];
  setSongSelection: (songSelection: number[]) => void;
}

export const SongSelection = ({
  songSelection,
  setSongSelection,
}: Props): JSX.Element => {
  const setChoice = (optionIndex: number, value: number) => {
    const newSongSelection = [...songSelection];
    newSongSelection[optionIndex] = value;
    setSongSelection(newSongSelection);
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="gold-border w-100 d-flex flex-column py-3 mb-3">
        {songList.map((options, optionIndex) => (
          <div
            key={optionIndex}
            className={
              'd-flex py-2 ' +
              (optionIndex < songList.length - 1 ? 'gold-bottom' : '')
            }
          >
            <div key={optionIndex} className="me-3 my-auto" style={{ width: '50px' }}>
              {optionIndex + 1}
              <input
                type="checkbox"
                className="form-check-input ms-2"
                checked={songSelection[optionIndex] >= 0}
                onChange={() => {
                  songSelection[optionIndex] >= 0
                    ? setChoice(optionIndex, -1)
                    : setChoice(optionIndex, 0);
                }}
              />
            </div>
            <div className="flex-grow-1">
              {options.map((song, songIndex) => (
                <div key={songIndex} className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id={`song-${optionIndex}-${songIndex}`}
                    disabled={songSelection[optionIndex] < 0}
                    checked={songSelection[optionIndex] === songIndex + 1}
                    onChange={() => {
                      setChoice(optionIndex, songIndex + 1);
                    }}
                  />
                  <label
                    htmlFor={`song-${optionIndex}-${songIndex}`}
                    className="form-check-label"
                  >
                    <strong>"{song.title}"</strong> (<em>{song.show}</em>)
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
