import {
  UploadStatus,
  useActiveStorageUpload,
} from '@bwymedia/react-active-storage';
import React, { useEffect } from 'react';
import { patchCustomerUpload } from '../lib/api';
import { StepProps } from '../lib/types';
import { directUploadUrl } from '../lib/urls';
import { ProgressBar } from './ProgressBar';
import { VideoInstructions } from './VideoInstructions';

export const StepFive = ({
  appState,
  setAppState,
  setStep,
}: StepProps): JSX.Element => {
  const { performUpload, handleChange, uploadState, file } =
    useActiveStorageUpload(directUploadUrl);

  useEffect(() => {
    if (file && process.env.REACT_APP_DEBUG) {
      console.log(file);
    }
  }, [file]);

  const constructPayload = (): Record<string, unknown> => ({
    metadata: {
      use_projections: appState.useProjections,
      name: appState.name,
      organization: appState.organization,
      email_address: appState.emailAddress,
      phone_number: appState.phoneNumber,
      in_us: appState.inUS,
      projector_rental: appState.projectorRental,
      song_selection: appState.songSelection,
    },
  });

  const handleUpload = async () => {
    try {
      const blob = await performUpload();
      const payload = Object.assign(constructPayload(), {
        video_original: blob.signed_id,
      });
      return submitPayload(payload);
    } catch (error) {
      if (process.env.REACT_APP_DEBUG) {
        console.error(error);
      }
    }
  };

  const handleJustSubmit = () => submitPayload(constructPayload());

  const submitPayload = async (payload: Record<string, unknown>) => {
    try {
      await patchCustomerUpload(appState.accessCode, payload);
      setStep(5);
    } catch (error) {
      if (process.env.REACT_APP_DEBUG) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div className="text-accent text-font-body text-center mb-3">
        <h1>Upload your "Theatre Is..." video</h1>
      </div>
      <div className="card text-dark bg-light mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div className="flex-grow-1">
              <input
                type="file"
                accept="video/mp4,video/quicktime"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="ms-3">
              <button
                type="button"
                className="btn btn-success"
                onClick={handleUpload}
                disabled={
                  uploadState.status === UploadStatus.None ||
                  uploadState.status >= UploadStatus.Initiated
                }
              >
                Upload Video
              </button>
            </div>
          </div>
          {uploadState.status >= UploadStatus.Initiated && (
            <div className="mt-3">
              <ProgressBar
                percent={uploadState.progress * 100}
                variant="success"
              />
            </div>
          )}
        </div>
      </div>
      {uploadState.status <= UploadStatus.Ready && (
        <div className="text-accent text-font-body text-center mb-3">
          <p>
            Not uploading your own video?
            <button
              type="button"
              className="btn btn-link"
              onClick={handleJustSubmit}
            >
              Skip this step
            </button>
          </p>
        </div>
      )}
      <div className="mt-3">
        <div className="card bg-dark bg-opacity-75">
          <div className="card-body">
            <VideoInstructions />
          </div>
        </div>
      </div>
    </div>
  );
};
