import React, { useState } from 'react';

import { StepProps } from '../lib/types';
import { getCustomerUpload } from '../lib/api';
import { Instructions } from './Instructions';

export const StepOne = ({
  appState,
  setAppState,
  setStep,
}: StepProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    setError(false);
    setLoading(true);
    try {
      await getCustomerUpload(appState.accessCode);
      setStep(1);
    } catch (error) {
      if (process.env.REACT_APP_DEBUG) {
        console.error(error);
      }
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div className="text-accent text-center d-flex flex-column align-items-center">
      <h3 className="mb-5">
        <strong>
          MTI's <em>All Together Now!</em>: A Global Event Celebrating Local
          Theatre
        </strong>{' '}
        is an opportunity for schools and theatres around the world to locally
        produce and perform an exclusive musical revue featuring songs from
        MTI's shows.
      </h3>
      {appState.locked ? (
        <h1
          className="display-3 mb-5 text-font-heading"
          style={{ fontSize: '3rem' }}
        >
          The submission window has closed.
          <br />
          You should be hearing from us soon!
        </h1>
      ) : (
        <>
          <h1
            className="display-3 mb-5 text-font-heading"
            style={{ fontSize: '4rem' }}
          >
            Late Submission
          </h1>
          <div className="mb-5">
            <Instructions />
          </div>
          <label
            htmlFor="mti-access-code"
            className="form-label text-font-body"
          >
            Enter your Access Code below:
          </label>
          <div className="d-flex align-items-center mb-3">
            <input
              type="text"
              id="mti-access-code"
              className="form-control form-control-lg me-3"
              style={{ maxWidth: '170px' }}
              value={appState.accessCode}
              onChange={(e) =>
                setAppState({ ...appState, accessCode: e.target.value })
              }
              onKeyDown={(e) => {
                e.key === 'Enter' && handleSubmit();
              }}
            />
            {loading ? (
              <button type="button" className="btn btn-lg btn-primary" disabled>
                <div className="d-flex justify-content-center">
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                    style={{ height: '30px', width: '30px' }}
                  ></span>
                </div>
                <span className="visually-hidden">Loading...</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-lg btn-primary"
                onClick={handleSubmit}
              >
                Go
              </button>
            )}
          </div>
          {error && (
            <div className="text-danger">
              There was a problem with the access code provided. Please try
              again.
            </div>
          )}
          <small>
            <a
              href="https://www.mtishows.com/mtis-all-together-now"
              className="link-accent text-font-body"
            >
              Don't have an Access Code?
            </a>{' '}
            |{' '}
            <a
              href="https://youtube.com/playlist?list=PLOAT6mWruzNRwxn4jvx0umh7HS6uYhxP4"
              className="link-accent text-font-body"
            >
              Watch Tutorial Videos
            </a>
          </small>
        </>
      )}
    </div>
  );
};
