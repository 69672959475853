import React, { useEffect, useState } from 'react';

import logo from '../img/Logos_gold_shadow.png';
import { AppState } from '../lib/types';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { StepFive } from './StepFive';
import { StepSix } from './StepSix';
import { StepSeven } from './StepSeven';
import { StepEight } from './StepEight';
import { Footer } from './Footer';

const steps = [
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  StepSix,
  StepSeven,
  StepEight,
];

const initialAppState: AppState = {
  locked: false,
  accessCode: '',
  useProjections: null,
  name: '',
  organization: '',
  emailAddress: '',
  phoneNumber: '',
  inUS: true,
  projectorRental: true,
  songSelection: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  custom_video_url: '',
};

// Only used when debugging
declare global {
  interface Window {
    goToStep: (step: number) => void;
    setAppState: (appState: AppState) => void;
  }
}

export const App = (): JSX.Element => {
  const [appState, setAppState] = useState(initialAppState);
  const [step, setStep] = useState(6);

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG) {
      window.goToStep = setStep;
      window.setAppState = setAppState;
    }
  }, []);

  return (
    <div className="vh-100 d-flex flex-column bg-dark">
      <main className="bg-img flex-grow-1 main-bottom-shadow main-padding-bottom">
        <div className="container mb-3">
          <img src={logo} className="img-fluid" alt="logo" />
        </div>
        <div className="small-container px-3 d-flex flex-column mx-auto">
          {React.createElement(steps[step], { appState, setAppState, setStep })}
        </div>
      </main>
      <Footer />
    </div>
  );
};
