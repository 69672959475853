import React from 'react';

export const Instructions = () => (
  <div className="card text-font-body text-accent text-start bg-dark bg-opacity-75">
    <div className="card-body">
      <h3 className="card-title">Instructions</h3>
      <p className="card-text">
        After entering your Access Code below, you will be taken through the
        process of customizing your production of <em>All Together Now!</em>{' '}
        <strong>Before starting this process,</strong> please ensure that you
        are prepared to do the following:
      </p>
      <ul>
        <li>
          You will be asked to select which songs you will be including in your
          production. <em>All Together Now!</em> has slots for 15 songs, and you
          can choose from 2 to 3 songs in each slot.
        </li>
        <li>
          There is the opportunity to include a custom "Theatre Is..." video in
          your production. Instructions and guidelines for creating this video
          have been sent to you via email. If you intend to include a custom
          video in your production,{' '}
          <strong>
            please wait to begin this process until your video file is ready to
            upload.
          </strong>
        </li>
      </ul>
    </div>
  </div>
);
