export interface Song {
  title: string;
  show: string;
}

export const songList: Song[][] = [
  [
    {
      title: 'Be Our Guest',
      show: "Disney's Beauty and the Beast",
    },
    {
      title: 'Pure Imagination',
      show: 'Charlie and the Chocolate Factory',
    },
  ],
  [
    {
      title: 'You Could Drive a Person Crazy',
      show: 'Company',
    },
    {
      title: 'Matchmaker',
      show: 'Fiddler on the Roof',
    },
    {
      title: 'Writing Down the Story of My Life',
      show: 'Junie B. Jones, The Musical',
    },
  ],
  [
    {
      title: 'Back to Before',
      show: 'Ragtime',
    },
    {
      title: 'Meadowlark',
      show: "The Baker's Wife",
    },
    {
      title: 'Middle of a Moment',
      show: "Roald Dahl's James and the Giant Peach",
    },
  ],
  [
    {
      title: 'Gimme, Gimme',
      show: 'Thoroughly Modern Millie',
    },
    {
      title: 'This is the Moment',
      show: 'Jekyll & Hyde',
    },
  ],
  [
    {
      title: 'Spread the Love Around',
      show: 'Sister Act',
    },
    {
      title: 'Life is So Peculiar',
      show: 'Five Guys Named Moe',
    },
    {
      title: "We're All In This Together",
      show: "Disney's High School Musical",
    },
  ],
  [
    {
      title: 'Empty Chairs at Empty Tables',
      show: 'Les Misérables',
    },
    {
      title: 'Tomorrow',
      show: 'Annie',
    },
  ],
  [
    {
      title: 'The Human Heart',
      show: 'Once on This Island',
    },
    {
      title: 'The New World',
      show: 'Songs for a New World',
    },
  ],
  [
    {
      title: 'I Dreamed a Dream',
      show: 'Les Misérables',
    },
    {
      title: "Somewhere That's Green",
      show: 'Little Shop of Horrors',
    },
    {
      title: 'She Used to Be Mine',
      show: 'Waitress',
    },
  ],
  [
    {
      title: 'Seize the Day',
      show: "Disney's Newsies",
    },
    {
      title: 'Consider Yourself',
      show: 'Oliver!',
    },
  ],
  [
    {
      title: 'When I Grow Up',
      show: "Roald Dahl's Matilda the Musical",
    },
    {
      title: 'Children Will Listen',
      show: 'Into the Woods',
    },
  ],
  [
    {
      title: 'Stop the World',
      show: 'Come from Away',
    },
    {
      title: 'Take a Chance on Me',
      show: 'Mamma Mia!',
    },
  ],
  [
    {
      title: 'Let It Go',
      show: "Disney's Frozen",
    },
    {
      title: "Wouldn't It Be Loverly",
      show: 'My Fair Lady',
    },
    {
      title: 'Astonishing',
      show: 'Little Women',
    },
  ],
  [
    {
      title: "Sit Down, You're Rockin' the Boat",
      show: 'Guys and Dolls',
    },
    {
      title: 'Supercalifragilisticexpialidocious',
      show: "Disney and Cameron Mackintosh's Mary Poppins",
    },
  ],
  [
    {
      title: 'Beautiful City',
      show: 'Godspell',
    },
    {
      title: 'Good Riddance (Time of Your Life)',
      show: "Green Day's American Idiot",
    },
    {
      title: 'Seasons of Love',
      show: 'Rent',
    },
  ],
  [
    {
      title: "You Can't Stop the Beat",
      show: 'Hairspray',
    },
    {
      title: 'Why We Tell the Story',
      show: 'Once On This Island',
    },
    {
      title: 'Seasons of Love',
      show: 'Rent',
    },
  ],
];
