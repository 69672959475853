import React, { useEffect } from 'react';

import { StepProps } from '../lib/types';
import { SongSelection } from './SongSelection';

export const StepThree = ({
  appState,
  setAppState,
  setStep,
}: StepProps): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const setSongSelection = (songSelection: number[]) => {
    setAppState({ ...appState, songSelection });
  };
  const songSelectionIncomplete = appState.songSelection.some(
    (selection) => selection === 0
  );

  return (
    <div className="d-flex flex-column align-items-center text-accent text-font-body">
      <div className="text-center">
        <h1>Select Your Songs</h1>
        <p>
          The All Together Now! Scenic Projections include a digital backdrop
          for each song selected.
        </p>
      </div>
      <SongSelection
        songSelection={appState.songSelection}
        setSongSelection={setSongSelection}
      />
      <button
        type="button"
        className="btn btn-lg btn-primary"
        disabled={songSelectionIncomplete}
        onClick={() => setStep(3)}
      >
        Continue
      </button>
    </div>
  );
};
