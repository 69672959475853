/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

export const Footer = (): JSX.Element => {
  return (
    <footer className="text-font-body">
      <div className="bg-footer-top text-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h1 className="text-font-logo text-accent text-uppercase position-relative">
                Broadway Media
                <a
                  href="https://www.broadwaymedia.com"
                  className="stretched-link"
                ></a>
              </h1>
              <p>
                Breaking down barriers to the performing arts through
                world-class production resources.
              </p>
              <p>
                <span className="text-muted">Want to learn more?</span>
                <br />
                <a
                  href="https://www.broadwaymedia.com/about/contact-us"
                  className="link-accent"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click to schedule a call
                </a>
              </p>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div className="row">
                <div className="col-4">
                  <h5 className="text-font-heading text-accent">Products</h5>
                  <div>
                    <a
                      href="https://www.broadwaymedia.com/products/digital-scenery/product/scenic-projections"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Scenic Projections
                    </a>
                    <br />
                    <a
                      href="https://www.broadwaymedia.com/products/digital-scenery/product/stage-player"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Stage Player
                    </a>
                    <br />
                    <a
                      href="https://form.jotform.com/212375232781050"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Stage Projectors
                    </a>
                  </div>
                </div>
                <div className="col-4">
                  <h5 className="text-font-heading text-accent">Company</h5>
                  <div>
                    <a
                      href="https://www.broadwaymedia.com/about/broadway-media"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      About Us
                    </a>
                    <br />
                    <a
                      href="https://www.broadwaymedia.com/news/blog"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Blog
                    </a>
                    <br />
                    <a
                      href="https://www.broadwaymedia.com/learn/help-center"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Help Center
                    </a>
                    <br />
                    <a
                      href="https://www.broadwaymedia.com/about/contact-us"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
                <div className="col-4">
                  <h5 className="text-font-heading text-accent">Learn More</h5>
                  <div>
                    <a
                      href="https://www.broadwaymedia.com/products/digital-scenery/explore/solutions"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Examples
                    </a>
                    <br />
                    <a
                      href="https://www.broadwaymedia.com/shows/scenic-projections"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Shows
                    </a>
                    <br />
                    <a
                      href="https://www.broadwaymedia.com/products/digital-scenery/features/features"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Features
                    </a>
                    <br />
                    <a
                      href="https://www.facebook.com/broadwaymedia"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    <br />
                    <a
                      href="https://www.instagram.com/broadwaymedia/"
                      className="link-light"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-footer-bottom py-5 font-size-12">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <p className="text-muted float-end">
                Copyright 2021 © Broadway Media Distribution, Inc. All rights
                reserved.
              </p>
              <div className="d-flex justify-content-between float-end">
                <div>
                  <a
                    href="https://www.broadwaymedia.com/about/terms"
                    className="link-accent text-uppercase me-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.broadwaymedia.com/about/terms"
                    className="link-accent text-uppercase me-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.broadwaymedia.com/about/terms"
                    className="link-accent text-uppercase me-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Accessibility
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.broadwaymedia.com/about/terms"
                    className="link-accent text-uppercase me-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cookies
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
