import React, { useEffect } from 'react';

import { StepProps } from '../lib/types';
import { songList } from '../data/songs';

export const StepFour = ({
  appState,
  setAppState,
  setStep,
}: StepProps): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const titleList = appState.songSelection
    .map((value, index) =>
      value < 0 ? null : songList[index][value - 1].title
    )
    .filter((value) => value !== null);

  return (
    <div className="d-flex flex-column align-items-center text-accent text-font-body">
      <div className="text-center mb-3">
        <h1>Confirm your song selection</h1>
      </div>
      <div className="gold-border w-100 d-flex flex-column py-3 mb-3">
        {titleList.map((title, index) => (
          <div
            key={index}
            className={
              'd-flex py-2 ' +
              (index < titleList.length - 1 ? 'gold-bottom' : '')
            }
          >
            <div className="me-4 my-auto" style={{ width: '20px' }}>
              {index + 1}
            </div>
            <div className="flex-grow-1">{title}</div>
          </div>
        ))}
      </div>
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-lg btn-secondary me-3"
          onClick={() => setStep(2)}
        >
          Edit
        </button>
        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={() => setStep(4)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};
