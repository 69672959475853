const serverOrigin =
  process.env.REACT_APP_SERVER_ORIGIN ||
  'https://bmd-accounts-staging.herokuapp.com';

export function customerUploadUrl(accessCode: string): URL {
  return new URL(`/customer_uploads/${accessCode}`, serverOrigin);
}

export const directUploadUrl = new URL(
  '/rails/active_storage/direct_uploads',
  serverOrigin
);
