import React from 'react';

import { StepProps } from '../lib/types';

export const StepEight = ({
  appState,
  setAppState,
  setStep,
}: StepProps): JSX.Element => {
  return (
    <div className="d-flex flex-column align-items-center text-accent text-font-body text-center">
      {appState.custom_video_url ? (
        <>
          <h3 className="mb-3">Your custom "Theatre Is..." video is ready!</h3>
          <div className="mb-4">
            <a
              className="btn btn-lg btn-success"
              href={appState.custom_video_url}
            >
              Download Video
            </a>
          </div>
        </>
      ) : (
        <h3 className="mb-4">
          We cannot find a custom "Theatre Is..." video for that access code.
          Please email{' '}
          <a href="mailto:help@broadwaymedia.com">help@broadwaymedia.com</a> for
          assistance.
        </h3>
      )}
      <h4 className="mb-3">Download all MTI-provided videos here: </h4>
      <div>
        <a className="btn btn-primary" href="https://broadway.media/atn-videos">
          Download MTI Videos
        </a>
      </div>
    </div>
  );
};
