import React from 'react';

import { StepProps } from '../lib/types';

export const StepSix = ({
  appState,
  setAppState,
  setStep,
}: StepProps): JSX.Element => {
  return (
    <div className="d-flex flex-column align-items-center text-accent text-font-body text-center">
      <h1>You're all set!</h1>
      <p className="lead">
        You will receive an email confirming your submission with further
        instructions if necessary.
      </p>
      <p className="lead">
        If you do not receive an email, please check your spam folder for
        messages from webmaster@broadwaymedia.com. Email{' '}
        <a href="mailto:help@broadwaymedia.com">help@broadwaymedia.com</a> if
        you need further assistance.
      </p>
      {appState.useProjections && (
        <>
          <h3 className="mb-3">
            To get the most out of your projections, we've prepared the
            following instructional videos:
          </h3>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed?listType=playlist&list=PLOAT6mWruzNRwxn4jvx0umh7HS6uYhxP4&index=3"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </>
      )}
    </div>
  );
};
