import React from 'react';

interface Props {
  percent: number;
  variant?: string;
  heightPx?: number;
}

export const ProgressBar = ({
  percent,
  variant,
  heightPx,
}: Props): JSX.Element => {
  let containerStyle = {};
  if (heightPx) {
    containerStyle = { height: `${heightPx}px` };
  }

  let barClass = 'progress-bar';
  if (variant) {
    barClass += ` bg-${variant}`;
  }

  return (
    <div className="progress" style={containerStyle}>
      <div
        className={barClass}
        style={{ width: `${percent}%` }}
        aria-valuenow={percent}
        aria-valuemin={0}
        aria-valuemax={100}
      ></div>
    </div>
  );
};
