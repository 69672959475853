import { customerUploadUrl } from "./urls";

export const getCustomerUpload = async (accessCode: string) => {
  const response = await fetch(customerUploadUrl(accessCode).toString(), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseJson = await response.json();
  if (process.env.REACT_APP_DEBUG) {
    console.log("API Response", responseJson);
  }
  if (!responseJson.success) {
    throw new Error(`Non-successful API response`);
  }
  return responseJson;
};

export const patchCustomerUpload = async (
  accessCode: string,
  payload: Record<string, unknown>
) => {
  const response = await fetch(customerUploadUrl(accessCode).toString(), {
    method: 'PATCH',
    body: JSON.stringify({ customer_upload: payload }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseJson = await response.json();
  if (process.env.REACT_APP_DEBUG) {
    console.log("API Response", responseJson);
  }
  if (!responseJson.success) {
    throw new Error(`Non-successful API response`);
  }
  return responseJson;
};
