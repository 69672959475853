import React from 'react';

export const VideoInstructions = () => (
  <div className="text-font-body text-accent">
    <p className="lead">
      MTI has created an approximately 2-minute-long video that features MTI
      authors and industry luminaries showcasing their views of what theatre is
      and why it's important.
    </p>
    <p className="lead">
      To make this exciting video even more customized, you can personalize this
      segment by creating and uploading a video from your own local talent and
      members of your community.
    </p>
    <p>
      If you choose not to film and upload local testimonials, you may skip this
      step and still access the MTI video for your show.
    </p>
    <p>
      If you do choose to upload a custom video, here are some helpful tips on
      how to film:
    </p>
    <ul>
      <li>Using a phone for recording is preferable</li>
      <li>Please make sure the phone is turned horizontally</li>
      <li>Ensure that you have ample lighting so the subject is visible</li>
      <li>
        Recording in any setting is fine, but please double-check that there's
        nothing inappropriate visible in the background
      </li>
      <li>
        Keep it short – the video will feature a number of participants, so all
        you need is around 5-10 seconds
      </li>
      <li>Begin your statement with "Theatre is…”</li>
      <li>
        If you have more than one interview, use a phone app, iMovie or Windows
        Movie Maker to combine the videos into one .mp4 or .mov file.
      </li>
    </ul>
  </div>
);
