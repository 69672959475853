import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { FileEarmarkPdf } from 'react-bootstrap-icons';

import { StepProps } from '../lib/types';

export const StepTwo = ({
  appState,
  setAppState,
  setStep,
}: StepProps): JSX.Element => {
  return (
    <div className="text-accent text-font-body d-flex flex-column align-items-center">
      <p className="lead text-center">
        Broadway Media is pleased to provide complimentary digital projections
        for your ATN! production. Included in the projections is the
        MTI-provided video content. Alternatively, if you do not plan to use the
        digital scenery, you will be able to download the MTI videos directly
        when they become available.
      </p>
      <ul className="list-group mb-3">
        <li className="list-group-item text-accent bg-dark bg-opacity-75">
          <div className="form-check d-flex align-items-center">
            <input
              type="radio"
              className="form-check-input me-3"
              id="projections-yes"
              onChange={() =>
                setAppState({ ...appState, useProjections: true })
              }
              checked={appState.useProjections === true}
            />
            <label htmlFor="projections-yes" className="fs-3">
              I <em className="text-success">will</em> be using Broadway Media
              projections.
            </label>
          </div>
          {appState.useProjections !== true && (
            <small style={{ marginLeft: '2.25em' }}>
              (Why not? They're free!)
            </small>
          )}
          {appState.useProjections === true && (
            <div>
              <p>
                Please provide the following information to help us deliver your
                free digital scenery.
              </p>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={appState.name}
                  onChange={(e) =>
                    setAppState({ ...appState, name: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Organization</label>
                <input
                  type="text"
                  className="form-control"
                  value={appState.organization}
                  onChange={(e) =>
                    setAppState({ ...appState, organization: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  value={appState.emailAddress}
                  onChange={(e) =>
                    setAppState({ ...appState, emailAddress: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="US"
                  className="form-control"
                  value={appState.phoneNumber}
                  onChange={(number) =>
                    setAppState({ ...appState, phoneNumber: number })
                  }
                />
              </div>
              <div className="d-flex mb-1">
                <div className="form-label me-4">Region:</div>
                <div className="me-4">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={appState.inUS}
                    onChange={() => setAppState({ ...appState, inUS: true })}
                    id="region-us"
                  />
                  <label htmlFor="region-us" className="form-check-label ms-2">
                    United States
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={!appState.inUS}
                    onChange={() => setAppState({ ...appState, inUS: false })}
                    id="region-rest"
                  />
                  <label
                    htmlFor="region-rest"
                    className="form-check-label ms-2"
                  >
                    Rest of World
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="projector-rental-quote"
                    className="form-check-input"
                    checked={appState.projectorRental}
                    onChange={() =>
                      setAppState({
                        ...appState,
                        projectorRental: !appState.projectorRental,
                      })
                    }
                  />
                  <label
                    htmlFor="projector-rental-quote"
                    className="form-check-label"
                  >
                    Please send me a projector rental quote
                  </label>
                </div>
              </div>
            </div>
          )}
        </li>
        <li className="list-group-item text-accent bg-dark bg-opacity-75">
          <div className="form-check d-flex align-items-center">
            <input
              type="radio"
              className="form-check-input me-3"
              id="projections-no"
              onChange={() =>
                setAppState({ ...appState, useProjections: false })
              }
              checked={appState.useProjections === false}
            />
            <label htmlFor="projections-no" className="fs-3">
              I <em className="text-danger">will not</em> be using Broadway
              Media projections.
            </label>
          </div>
          {appState.useProjections === false && (
            <div>
              <p>
                In the following step you will be uploading custom videos to be
                combined with videos provided by MTI. Please provide an email
                address to deliver the final composite video.
              </p>
              <div className="mb-3">
                <label htmlFor="email-address" className="form-label">
                  Email Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email-address"
                  value={appState.emailAddress}
                  onChange={(e) =>
                    setAppState({ ...appState, emailAddress: e.target.value })
                  }
                />
              </div>
            </div>
          )}
        </li>
      </ul>
      <div className="mb-4">
        <button
          type="button"
          className="btn btn-lg btn-primary"
          disabled={appState.useProjections === null}
          onClick={() => {
            setStep(appState.useProjections ? 2 : 4);
          }}
        >
          Next
        </button>
      </div>
      <div className="row align-items-center">
        <div className="col-8">
          <p className="lead text-center mb-0">
            Still not convinced? Check out a preview of what Broadway Media has
            to offer:
          </p>
        </div>
        <div className="col-4">
          <div className="text-center">
            <a
              href="ATN%21%20Digital%20Scenery%20by%20Broadway%20Media.pdf"
              target="_blank"
              rel="noreferrer"
              className="btn btn-large btn-success"
            >
              <FileEarmarkPdf className="bi" /> Digital Brochure
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
